.MuiTypography-body1 {
  padding: 10px!important;
  height: 80px!important;
  font-size: 17px !important;
  
}

.form-group{
  margin-top: 17px!important;
  display: flex !important;
  margin: 20px!important;
}


