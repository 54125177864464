.img {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
}

.main {
    background: "black";
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    display: inline-block;
}

.button {
    margin: 50px;
}